<template>
  <section class="body">
    <section class="banner">
      <div class="wrap">
        <img class="logo" src="../../assets/v3/ciie/logo.png" width="484" height="96" />
        <h2>橙仕安特威防疫车型<strong>亮相进博会</strong></h2>
        <p>
          <span class="date">2022年11月5日-11月10日</span>
          <span class="address">国家会展中心(上海市青浦区崧泽大道333号)</span>
        </p>
        <img src="../../assets/v3/ciie/view.png" width="996" height="508" class="img" >
      </div>
    </section>

    <article class="ciie">
      <p class="intro"><strong>第五届中国国际进口博览会</strong>于2022年11月5日-10日在国家会展中心（上海）举行。橙仕携安特威全系列智能防疫车盛装参会。在疫情多变，动态清零的政策下，作为提升疫情防控能力的重要抓手的核酸检测工作，如何通过科技力量，降低人力物力投入，又能更快速精准对“阳性”人员进行追踪、管理？如何有效通过对活动于城市神经末梢的城配业务进行消杀管理，防止“物传人”？本次亮相进博会的橙仕安特威防疫车型通过多项黑科技的升级，带来全新防疫解決方案。</p>
      <div class="views">
        <img src="../../assets/v3/ciie/p1.jpg" width="564" height="374" />
        <img src="../../assets/v3/ciie/p2.jpg" width="268" height="182" />
        <img src="../../assets/v3/ciie/p3.jpg" width="268" height="182" />
        <img src="../../assets/v3/ciie/p4.jpg" width="268" height="182" />
        <img src="../../assets/v3/ciie/p5.jpg" width="268" height="182" />
      </div>
      <h3>橙仕汽车诚邀您莅临进博会一起感受防疫黑科技!</h3>
      <p>时间：2022年11月5日-11月10日</p>
      <p>地点：国家会展中心（上海市青浦区松泽大道333号）</p>
      <p>展馆位置：8.1 C6-02 医疗器械及医药保健展区-<br class="mShow" />公共卫生防疫展区</p>
    </article>

  </section>
</template>

<script>
export default {
  name: "CIIE",
};
</script>

<style lang="scss" scoped>
.banner {
  margin-bottom: 240px;
  font-size: 24px;
  color: #fff;
  line-height: 26px;
  background-color: #E83E0B;
  
  .wrap {
    position: relative;
    top: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-bottom: 140px;
  }

  h2 {
    margin: 40px 0 28px;
    color: #fff;
    font-size: 80px;
    line-height: 96px;

    strong {
      font-weight: bold;
    }
  }

  .date, .address {
    display: inline-block;
    vertical-align: top;
    padding: 0 30px;
    
    &::before {
      display: inline-block;
      vertical-align: top;
      margin-right: 12px;
      content: '';
      width: 26px;
      height: 26px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cg fill='%23fff'%3E%3Cpath d='M151.65 149.81c-2.4-2.7-5.39-4.83-8.9-6.33-5.87-2.5-13.44-3.31-20.84-.5-.77.29-1.5.62-2.21.98l2.46-12.14h32.47V114.8h-46.59l-9.69 49.47h18.16l.64-1.69c.82-2.17 2.07-3.6 3.84-4.35 4-1.72 9.18-1.78 12.52-.21 1.61.76 2.88 1.73 3.86 2.97 1.02 1.27 1.79 2.78 2.31 4.48.54 1.8.81 3.7.81 5.66 0 2.25-.23 4.39-.68 6.33-.42 1.82-1.11 3.36-2.1 4.7-.94 1.28-2.16 2.26-3.7 3.01-1.56.75-3.55 1.13-5.91 1.13-3.68 0-6.51-.93-8.65-2.86-2.12-1.9-3.31-4.65-3.64-8.39l-.21-2.39H97.31l.1 2.72c.17 4.44 1.09 8.39 2.75 11.74 1.66 3.37 3.93 6.24 6.75 8.55 2.79 2.28 6.06 4.02 9.72 5.15 3.59 1.12 7.52 1.68 11.69 1.68 5.32 0 9.99-1.01 13.9-3 3.86-1.98 7.08-4.54 9.56-7.62a30.03 30.03 0 0 0 5.44-10.24c1.11-3.65 1.67-7.27 1.67-10.75 0-4.11-.61-8.01-1.81-11.57-1.22-3.63-3.05-6.83-5.43-9.51z'/%3E%3Cpath d='M204.86 34.56H193.5v-8.47c0-3.03-2.5-5.51-5.59-5.51h-8.04c-3.08 0-5.59 2.47-5.59 5.51v8.47H81.71v-8.47c0-3.03-2.5-5.51-5.58-5.51h-8.05c-3.08 0-5.58 2.47-5.58 5.51v8.47H51.14c-16.85 0-30.56 13.7-30.56 30.54v139.77c0 16.84 13.71 30.54 30.56 30.54h153.71c16.85 0 30.56-13.7 30.56-30.54V65.1c.01-16.84-13.7-30.54-30.55-30.54zm0 181.65H51.14c-6.26 0-11.35-5.09-11.35-11.33V95.69H216.2v109.18c0 6.25-5.09 11.34-11.34 11.34zM68.08 67.75h8.05c3.08 0 5.58-2.47 5.58-5.51v-8.47h92.57v8.47c0 3.03 2.5 5.51 5.59 5.51h8.04c3.08 0 5.59-2.47 5.59-5.51v-8.47h11.36c6.26 0 11.35 5.08 11.35 11.33v11.38H39.8V65.1c0-6.25 5.09-11.33 11.35-11.33H62.5v8.47c0 3.04 2.5 5.51 5.58 5.51z'/%3E%3C/g%3E%3C/svg%3E") 50%;
      background-size: contain;
    }
  }

  .address {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cg fill='%23fff'%3E%3Cpath d='M228.32 108.13C228.75 52.42 183.51 7.16 128 7.62 72.5 7.16 27.25 52.42 27.68 108.13c-.55 40.93 54.76 101.02 83.65 130.26l5.09 5.11c3.04 3.13 7.22 4.89 11.58 4.89s8.54-1.76 11.58-4.89c26.84-26.75 89.34-91.9 88.74-135.37zm-20.69 2.42c-1.67 26.3-33.09 68.26-78.06 113.78l-1.56 1.55-5.81-5.92c-44.23-45.42-74.21-86.73-73.87-111.56-.34-44.6 35.53-80.52 79.51-80.14h.34c43.99-.38 79.85 35.55 79.51 79.71l-.06 2.58z'/%3E%3Cpath d='M130.13 70.19c-20.46 0-37.05 16.6-37.05 37.06s16.6 37.05 37.06 37.05c20.46-.01 37.05-16.6 37.05-37.06 0-20.47-16.59-37.06-37.06-37.05zm16.26 38.53c-.81 8.83-8.5 15.41-17.34 14.85-8.85-.55-15.65-8.04-15.36-16.9.29-8.86 7.58-15.88 16.44-15.85h.02l1.91.12c8.8 1.08 15.15 8.95 14.33 17.78z'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  .img {
    margin-top: 30px;
    max-width: 100%;
    width: 996px;
    height: auto;
  }
}

.ciie {
  margin: 60px auto 200px;
  padding: 0 20px;
  max-width: 1160px;
  font-size: 26px;
  line-height: 36px;
  text-align: center;

  .intro {
    line-height: 40px;
    text-indent: 2em;
    text-align: justify;

    strong {
      font-size: 36px;
      font-weight: normal;
    }
  }

  h3 {
    margin-bottom: 12px;
    font-size: 34px;
    line-height: 40px;
    font-weight: bold;
  }

  .views {
    margin: 24px 0 48px;
    overflow: hidden;

    img {
      float: left;
      margin: 0 0 10px 10px;

      &:first-child {
        margin: 0;
      }
    }
  }
}


@media only screen and (max-width: 500px) {
  .banner {
    margin-bottom: 100px;
    font-size: 14px;
    line-height: 20px;
    
    .wrap {
      top: 100px;
      padding: 60px 0;
    }

    .logo {
      width: 242px;
      height: auto;
    }

    h2 {
      margin: 20px 0 12px;
      font-size: 24px;
      line-height: 36px;
    }

    .date, .address {
      display: block;
      padding: 4px 0;
      
      &::before {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }

    .img {
      margin-top: 48px;
      width: 100%;
      height: auto;
    }
  }

  .ciie {
    margin: 30px auto 100px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 20px;


    h3 {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 24px;
    }

    p {
      padding: 2px 0;
    }

    .intro {
      padding: 0 12px;
      line-height: 24px;

      strong {
        font-size: 18px;
      }
    }

    .views {
      margin: 12px 12px 24px;
      column-count: 2;
      column-gap: 8px;

      img {
        display: block;
        float: none;
        width: 100%;
        height: auto;
        margin: 0 0 8px;

        &:first-child {
          margin: 0 0 8px;
          column-span: all;
        }
      }
    }
  }
}
</style>